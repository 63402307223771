import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { reducers } from "../Reducers"

const composeEnhancers = (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']  as typeof compose || compose;
    
const enhancer = composeEnhancers(
    applyMiddleware(thunk),
    // other store enhancers if any
    );

const store = createStore(reducers(),
// , composeWithDevTools(
//     applyMiddleware(thunk)
// ),
    enhancer
);

export default store;