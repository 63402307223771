import React, { useCallback } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector, useDispatch } from 'react-redux';
import { IStanice, IUnitData } from '../interfaces';
import { Box, Button, FormControlLabel, Grid, Radio, Switch } from '@material-ui/core';
import { actionEnableError, actionSetCenter } from '../Actions/UnitAction';
import axios from 'axios'
import { actionTypes } from '../Actions/ActionTypes';
import { mapDirectionToLetter } from '../interfaces';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
    }),
);

interface IActions {
    actionEnableError: typeof actionEnableError;
    actionSetCenter: typeof actionSetCenter;
}

type Props = IStanice & IActions;

export default function UnitList() {
    const { stanice } = useSelector<IStanice, Props>((state: any) => {
        return { ...state.units };
    });

    const dispatch = useDispatch();
    const enableError = useCallback(
        (item) => dispatch({ type: actionTypes.EnableError, payload: { ...item } }),
        [dispatch]
    )

    const setCenter = useCallback(
        (item) => dispatch({ type: actionTypes.MapCenterChanged, payload: { ...item } }),
        [dispatch]
    )

    const { mapCenter } = useSelector<IStanice, Props>((state: any) => {
        return { ...state.units };
    });

    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);

    const handleToggle = (value: IUnitData) => () => {
        const currentIndex = checked.find((item: IUnitData) => item.id === value.id);
        const newChecked = [...checked];

        if (!currentIndex) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const DoItemAction = async (itemData: IUnitData, activate: boolean) => {
        const url = `/api/FRUnits/ControlValve`;

        await axios.post(
            url,
            {
                UnitID: itemData.id,
                Activate: activate
            }
        ).then(data => {
            const newObject: IUnitData = { ...itemData, isError: "", isActivated: activate };
            enableError(newObject);
        }).catch(error => {
            if (error.response) {
                const newObject: IUnitData = { ...itemData, isError: error.response.data.detail, isActivated: false };
                enableError(newObject);
            } else {
                const newObject: IUnitData = { ...itemData, isError: error, isActivated: false };
                enableError(newObject);
            }
        });
    }

    const onActivated = async (activate: boolean) => {
        checked.forEach((itemData: IUnitData) => {
            DoItemAction(itemData, activate);
        });
    }

    const fokusiraj = (item: IUnitData) => {
        setCenter({ latitude: item.currentLocation.latitude, longitude: item.currentLocation.longitude });
    }

    const isFocused = (item: IUnitData) => {
        return mapCenter.latitude === item.currentLocation.latitude && mapCenter.longitude === item.currentLocation.longitude;
    }

    return (
        <>
            <Grid container spacing={3} >
                <Grid item>
                    <Button variant="outlined" onClick={(e) => { onActivated(true); }} >Aktiviraj izabrane</Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" onClick={(e) => { onActivated(false); }}>Deaktiviraj izabrane</Button>
                </Grid>
            </Grid>
            <List className={classes.root}>
                {stanice.map((item: IUnitData) => {
                    const labelId = `checkbox-list-label-${item.id}`;
                    const infoText = <span>Temp:{item.temperature}, Hum:{item.humidity}<br />Brzina:{item.windSpeed}, Smer={mapDirectionToLetter(item.windDirection)}</span>;

                    return (
                        <ListItem key={item.id} dense button onClick={handleToggle(item)}
                            disabled={!item.isActive}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={checked.find((obj) => obj.id === item.id) !== undefined}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`Stanica: ${item.name}, status:` + (item.isActive ? "Povezan" : "Nepovezan")} secondary={infoText} />

                            <ListItemSecondaryAction>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Switch checked={item.isActivated} disabled={!item.isActive} onClick={() => { DoItemAction(item, !item.isActivated) }} />
                                        }
                                        label={item.isActivated ? "Deaktiviraj" : "Aktiviraj  "}
                                    /></Box>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Radio checked={isFocused(item)} disabled={!item.isActive} onClick={() => { fokusiraj(item) }} />
                                        }
                                        label={"Fokusiraj"}
                                    />
                                </Box>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </List>
        </>
    );

}