import { Action } from 'redux';


export type IUnitData = {
    id: string;
    name: string;
    windSpeed?: string;
    windDirection?: number;
    pressure?: string;
    temperature?: string;
    humidity?: string;
    currentLocation?: ILocation;
    isActive?: boolean;
    isActivated: boolean;
    isError: string;
    gasPercentage?: number;
    tankWeight?: number;
    batteryVoltage?: number;
}
export type IItem = {
    itemData: IUnitData;
}
export interface ILocation {
    longitude: number;
    latitude: number;
    altitude?: number;
    date?: Date;
    range?: number;
}


export type ITrackedItemDataStates = IStanice & {
    polyline?: [];
    resetToDefault(): void;
    goTo(item: IUnitData): void;
    //viewPort?: IViewPort | null;
    trackedItem?: IUnitData | null;
    trackedITemEnabled?: boolean;
}

export interface Payload<T> {
    payload: T
};

export interface PayloadAction<T> extends Action {
    payload: T
}

export interface IStanice {
    stanice?: IUnitData[];
    mapCenter?: ILocation;
}

export function mapDirectionToLetter(direction: number) {
    switch (direction) {
        case 0:
            return 'N';

        case 1:
            return 'NE';

        case 2:
            return 'E';

        case 3:
            return 'SE';

        case 4:
            return 'S';

        case 5:
            return 'SW';

        case 6:
            return 'W';

        case 7:
            return 'NW';
        case -1:
            return "Nije dostupno";
    }
}