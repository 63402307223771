import React from "react";
import { TileLayer } from "react-leaflet";
import { connect } from "react-redux";
import { IUnitData } from "../interfaces";
import Station from "./Station";

import { actionLoadUnits } from "../Actions/UnitAction"

interface dispatchProps {
    actionLoadUnits: typeof actionLoadUnits;
}

interface IStaniceData {
    stanice?: IUnitData[];
}

type Props = dispatchProps & IStaniceData;

export class ItemMap extends React.Component<Props>  {

    render() {

        const listItems = this.props.stanice === undefined ? [] : this.props.stanice.map(d => (
            <Station key={this.props.stanice.indexOf(d)} itemData={d}  ></Station>
        ));

        return (
            <>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                {listItems}
            </>
        )
    }
}

const mapStateToProps = (state: any) => {

    const { units } = state;
    const { stanice } = units;

    return { stanice: stanice }
};

const mapDispatchToProps = {
    actionLoadUnits
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemMap);
