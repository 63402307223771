import { Box, Container, Grid, } from '@material-ui/core';
import * as React from 'react';
import Mapa from './Mapa';
import UnitList from './UnitList'

const Home = () => (
  <div>
    <Grid container >
      <Grid item xl={4} md={6} sm>
        <Container>
        <h3>Sistem za kontrolu jedinica</h3>
          <UnitList />
        </Container>
      </Grid >
      <Grid item xl md={6} sm>
        <Box p={5}>
          <Mapa />
        </Box>
      </Grid >
    </Grid>
  </div>
);
export default Home;
