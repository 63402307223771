import React from "react";
import { IItem, IUnitData } from "../interfaces/index";
import axios from "axios";
import { Marker, Tooltip, Popup } from "react-leaflet";
import { Button } from "@material-ui/core";
import errorIcon from '../Images/error.png';
import { actionEnableError } from "../Actions/UnitAction"
import { connect } from "react-redux";
import * as L from 'leaflet';
import { Tooltip as MaterialToolTip } from '@material-ui/core';
import Polyline from 'react-leaflet-arrowheads';
import { mapDirectionToLetter } from '../interfaces'

interface IActions {
    actionEnableError: typeof actionEnableError
}

type Props = IItem & IActions;

class Station extends React.Component<Props> {

    async executeGo() {
        const url = `/api/FRUnits/ControlValve`;

        await axios.post(
            url,
            {
                UnitID: this.props.itemData.id,
                Activate: !this.props.itemData.isActivated
            }
        ).then(data => {
            const newObject: IUnitData = { ...this.props.itemData, isError: "", isActivated: !this.props.itemData.isActivated };
            this.props.actionEnableError(newObject);
        }).catch(error => {
            if (error.response) {
                const newObject: IUnitData = { ...this.props.itemData, isError: error.response.data.detail, isActivated: false };
                this.props.actionEnableError(newObject);
            } else {
                const newObject: IUnitData = { ...this.props.itemData, isError: error, isActivated: false };
                this.props.actionEnableError(newObject);
            }
        });
    }

    // getLetter = (windDirection: number) => {
    //     switch (this.props.itemData.windDirection) {
    //         case 0:
    //             return 'N';

    //         case 1:
    //             return 'NE';

    //         case 2:
    //             return 'E';

    //         case 3:
    //             return 'SE';

    //         case 4:
    //             return 'S';

    //         case 5:
    //             return 'SW';

    //         case 6:
    //             return 'W';

    //         case 7:
    //             return 'NW';
    //         case -1:
    //             return "Nije dostupno";
    //     }
    // }

    render() {

        let angle = 0;

        switch (this.props.itemData.windDirection) {
            case 0:
                break;
            case 1:
                angle = 45;
                break;
            case 2:
                angle = 90;
                break;
            case 3:
                angle = 135;
                break;
            case 4:
                angle = 180;
                break;
            case 5:
                angle = 225;
                break;
            case 6:
                angle = 270;
                break;
            case 7:
                angle = 315;
                break;
        }

        const { latitude, longitude } = this.props.itemData.currentLocation;

        let polylineData: L.LatLngExpression[] = [[0, 0]];
        const radius = 0.002;

        const sinx = radius * Math.sin(angle * Math.PI / 180);
        const cosx = radius * Math.cos(angle * Math.PI / 180);

        const newLatitude = cosx + latitude;
        const newLongitude = sinx * 1.5 + longitude;

        polylineData = [
            [latitude, longitude],
            [newLatitude, newLongitude]
        ];

        return (
            <>
                <Polyline positions={polylineData} color={'red'} arrowheads={{ size: '10%', frequency: '50m', yawn: 60, fill: false, color: 'red' }} />

                <Marker key={this.props.itemData.id} position={[this.props.itemData.currentLocation!.latitude, this.props.itemData.currentLocation!.longitude]}>

                    <Tooltip permanent={true} interactive={true} >
                        <div id="panel">
                            <label>Name: </label>
                            <label>{this.props.itemData.name}</label>
                        </div>
                    </Tooltip>
                    <Popup disabled={true}>
                        <div >
                            <i className="fa fa-map-marker" style={{ color: "red" }}></i>

                            <div id="panel">
                                <label>ID:</label>
                                <label>{this.props.itemData.id}</label>
                            </div>
                            <div id="panel">
                                <label>Name: </label>
                                <label>Stanica {this.props.itemData.name}</label>
                            </div>

                            <div id="panel">
                                <label>Location: </label>
                                <label>{this.props.itemData.currentLocation!.latitude}, {this.props.itemData.currentLocation!.longitude}</label>
                            </div>
                            <div id="panel">
                                <label>Temperatura: </label>
                                <label>{this.props.itemData.temperature}°C</label>
                            </div>
                            <div id="panel">
                                <label>Relativna vlažnost: </label>
                                <label>{this.props.itemData.humidity}%</label>
                            </div>
                            <div id="panel">
                                <label>Vazdušni pritisak: </label>
                                <label>{this.props.itemData.pressure}mbar</label>
                            </div>
                            <div id="panel">
                                <label>Brzina vetra: </label>
                                <label>{this.props.itemData.windSpeed} m/s</label>
                            </div>
                            <div id="panel">
                                <label>Smer vetra: </label>
                                <label>{mapDirectionToLetter(this.props.itemData.windDirection)}</label>
                            </div>
                            <div id="panel">
                                <label>Koncentracija gasa u vazduhu: </label>
                                <label>{this.props.itemData.gasPercentage}</label>
                            </div>
                            <div id="panel">
                                <label>Količina gasa u boci: </label>
                                <label>{this.props.itemData.tankWeight}</label>
                            </div>
                            <div id="panel">
                                <label>Napon baterije: </label>
                                <label>{this.props.itemData.batteryVoltage}</label>
                            </div>

                            <MaterialToolTip title={this.props.itemData.isError ? this.props.itemData.isError : ""} arrow>
                                <Button disabled={!this.props.itemData.isActive} variant="outlined" onClick={() => this.executeGo()}>
                                    <div style={{ padding: 8, visibility: this.props.itemData.isError === "" ? "collapse" : "visible" }}>
                                        <img src={errorIcon} width={32} height={32} alt="nema slike"></img>
                                    </div>
                                    {!this.props.itemData.isActivated ? "Aktiviraj" : "Deaktiviraj"}
                                </Button>
                            </MaterialToolTip>
                        </div>
                    </Popup >
                </Marker >
            </>
        );
    }
}

const mapDispatchToProps = {
    actionEnableError
};

export default connect(null, mapDispatchToProps)(Station);
