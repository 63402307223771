import { actionTypes } from '../Actions/ActionTypes'
import { IStanice, IUnitData } from '../interfaces';


const defaultState = { stanice: [], mapCenter: { latitude: 44.8182323, longitude: 20.2984481 } };

export const unitsReducer = (state: IStanice = defaultState, action: { type: string, payload }) => {

    if (action.type === actionTypes.LoadUnits) {

        const newItems = (state.stanice as IUnitData[]).filter(value => value.isError !== null);
        const payload = { ...action.payload };
        payload.stanice.forEach((element: IUnitData) => {
            const oldError = newItems.filter(x => x.id === element.id);
            if (oldError.length > 0) {
                element.isError = oldError[0].isError === undefined ? "" : oldError[0].isError;
            }
        });

        const newState = {
            ...state,
            stanice: payload.stanice,

        }
        return newState;
    }

    if (action.type === actionTypes.EnableError) {

        const { stanice } = state;
        const payload = action.payload as IUnitData;
        const index = stanice.findIndex((item: IUnitData) => item.id === payload.id)


        const newStanice = [].concat(stanice.slice(0, index), payload, stanice.slice(index + 1));

        const newState = {
            ...state,
            stanice: newStanice.map((x) => Object.assign({}, x))
        }
        return newState;
    }

    if (action.type === actionTypes.MapCenterChanged) {
        console.log(state);
        
        const newState = {
            ...state,
            mapCenter: action.payload
        } 
        return newState;
    }

    return state;
}