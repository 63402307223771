import React, { Component } from "react";
import { Map } from "react-leaflet";
import { connect } from "react-redux";
import { actionLoadUnits, actionSetCenter } from "../Actions/UnitAction"
import { IStanice, IUnitData } from "../interfaces";
import ItemMap from "./ItemMap";
import axios from "axios";

interface dispatchProps {
    actionLoadUnits: typeof actionLoadUnits;
    actionSetCenter: typeof actionSetCenter;
}

type Props = dispatchProps & IStanice;

export class Mapa extends Component<Props> {

    componentDidMount = () => {
        this.setReloadFunction();
    }

    setReloadFunction = () => {
        setTimeout(() => {

            this.loadUnits();
            this.setReload();
            this.setReloadFunction();
        }, 7000);
    }

    setReload = () => {
        setTimeout(this.loadUnits, 5000);
    }

    loadUnits = async () => {
        const response =
            await axios.get(
                `/api/FRUnits`,
                {
                    params: {}, headers: {
                        'Access-Control-Allow-Origin': '*',
                        "Content-Type": "application/json"
                    }
                }
            );


        const newStanice = response.data.map((item: any) => {
            return {
                id: item.UnitID,
                name: response.data.indexOf(item),
                humidity: item.Humidity,
                temperature: item.Temperature,
                isError: "",
                pressure: item.BarrometricPressure,
                windDirection: item.WindDirection,
                windSpeed: item.WindSpeed,
                isActivated: false,
                isActive: item.isActive,
                currentLocation: {
                    // latitude: 44.8182323 + 0.9 / 100,
                    // longitude: 20.2984481 - 0.1 / 100
                    latitude: item.Latitude,
                    longitude: item.Longitude
                },
                gasPercentage: item.GasPercentage,
                tankWeight: item.TankWeight,
                batteryVoltage: item.BatteryVoltage
            } as IUnitData;
        }
        );

        const payload = {
            stanice: newStanice
        };

        this.props.actionLoadUnits(payload);
        // this.setReload();
    };

    onViewportChanged = (viewport) => {
        console.log(viewport);
        this.props.actionSetCenter({ latitude: viewport.center[0], longitude: viewport.center[1] });

    }

    render() {
        return (
            <>
                <Map
                    center={[this.props.mapCenter.latitude, this.props.mapCenter.longitude]} zoom={15} scrollWheelZoom={false} onViewportChanged={this.onViewportChanged}>
                    {<ItemMap />}
                </Map>
            </>
        )
    }
}

const mapStateToProps = (state: any) => {

    const { units } = state;
    const { stanice, mapCenter } = units;
    return { stanice: stanice, mapCenter: mapCenter }
};

const mapDispatchToProps = {
    actionLoadUnits,
    actionSetCenter
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapa);