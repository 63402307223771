import { ILocation, IStanice, IUnitData } from "../interfaces/index"
import { actionTypes } from "./ActionTypes"

export const actionLoadUnits = (payload: IStanice) => {
    return {
        type: actionTypes.LoadUnits,
        payload
    }
}

export const actionEnableError = (payload : IUnitData) =>{
    return {
        type: actionTypes.EnableError,
        payload
    }
}

export const actionSetCenter = (payload : ILocation) =>{
    return {
        type: actionTypes.MapCenterChanged,
        payload
    }
}